<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">
        Raport produse
      </div>

      <div
        class="col2_subHead"
        style="margin-top: 1.2rem; display: flex; justify-content: flex-end;"
      >
        <div class="Button1 Btn_center" @click="generateExcel">
          <div class="icon">
            <i class="fas fa-download"></i> 
          </div>
          <span class="Btn_content"> Descarcă </span>
        </div>
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          apiModuleName="ACQUISITION_PRODUCTS_APPROVED"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";

import PermisionsMixin from "../mixins/acquisitionProductsPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import {
  ACQUISITION_PRODUCTS_APPROVED,
  ACQUISITION_PRODUCTS_GENERATE,
} from "../api.js";
import TableParent from "@/components/TableParent";

export default {
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
  },
  data() {
    return {
      tableHead: [
        {
          title: "Referat",
          queryKey: "needReferate.name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Creatorul referatului",
          queryKey: "needReferateCreator",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Produs",
          queryKey: "product.name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Unitatea de măsură",
          queryKey: "measurementUnit.shortName",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cantitate inițială",
          queryKey: "need.qty",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoare unitară ințială",
          queryKey: "need.estimatedPriceWoTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoarea totală ințială, fără TVA",
          queryKey: "totalValueWithoutTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cota TVA ințială",
          queryKey: "need.cotaTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoarea totală ințială, cu TVA",
          queryKey: "totalValueWithTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cantitate",
          queryKey: "quantity",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoare unitară",
          queryKey: "unitValue",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoarea totală, fără TVA",
          queryKey: "totalValueWithoutTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cotă TVA",
          queryKey: "tvaValue",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoarea totală, cu TVA",
          queryKey: "totalValueWithTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
      ],
      tableModule: ACQUISITION_PRODUCTS_APPROVED,
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    reload() {
      this.reloadTable++;
    },
    prepareData(row) {
      const preparation = [
        row.needReferateName,
        `${row.createdBy}<br><span style="color:#7c7c7c;">${
          row.departamentName
        }<br>${this.changeRoleView(row.roleName)}</span>`,
        row.productName || "-",
        row.measurementUnit,
        this.prepareLocaleNumber(row.needQty) || "-",
        this.prepareLocaleNumber(row.estimatedPriceWoTva) || "-",
        this.prepareLocaleNumber(row.estimatedTotalPriceWoTva) || "-",
        this.prepareLocaleNumber(row.cotaTva) || "-",
        this.prepareLocaleNumber(row.sumNeed) || "-",
        this.prepareLocaleNumber(row.quantity) || "-",
        this.prepareLocaleNumber(row.unitValue) || "-",
        this.prepareLocaleNumber(row.totalValueWithoutTva) || "-",
        this.prepareLocaleNumber(row.tvaValue) || "-",
        this.prepareLocaleNumber(row.totalValueWithTva) || "-",
      ];

      return preparation;
    },
    generateExcel() {
      ACQUISITION_PRODUCTS_GENERATE()
        .then((res) => {
          var blob = new Blob([res], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, `${uuidv4()}.xlsx`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }
    this.updateRoleStatus();
  },
};
</script>
<style></style>
